<template>
	<div class="monte_creneaux">
		<div class="form-row align-items-center">
			<div class="col">
	            <e-select
					v-model="selected_stallion"
	                :options="stallion"
	                :searchable="true"
	                label="horse_nom"
				>	
					<template slot="singleLabel" slot-scope="{ option }">{{ option.horse_nom }}</template>
					<template slot="noOptions">{{ $t('global.list_empty') }}</template>
				</e-select>
			</div>
			<div class="col-auto ml-auto">
	            <b-button @click="deleteEtalon" variant="secondary">
					<font-awesome-icon :icon="['fal', 'trash-alt']" />
				</b-button>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
    import Horse from "@/mixins/Horse.js"

	export default {
		name: 'ConfigEtalon',
		props: ['index', 'delete_creneau', 'horse', 'stallion'],
		mixins: [Horse],
		data () {
			return {
				selected_stallion: {},
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				if(this.horse) {
					this.selected_stallion = this.horse.planninghorse_stallion
				}
			},

			deleteEtalon() {
				this.$emit('update:delete_stallion', this.index)
			},

			getForm() {
				return this.selected_stallion
			},
		},
	}

</script>